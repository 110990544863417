@import "../helpers/variables";

.title {
	&--huge {
		font-family: $font-stack-druk;
		font-size: 62px;
		font-weight: $font-weight-light;
	}

	&--1 {
		font-family: $font-family-druk;
		font-size: 42px;

		@media (max-width: 600px) {
			font-size: 28px;
		}
	}

	&--2 {
		font-family: $font-family-druk;
		font-size: 28px;
		color: $color-gray-dark;
	}

	&--3 {
		font-family: $font-family-druk;
		font-size: 13px;
		font-weight: $font-weight-black;
		color: $color-gray-dark;
	}

	&--4 {
		// old 2 for device status
		font-family: $font-family-default;
		font-size: 16px;
		font-weight: $font-weight-black;
		text-transform: uppercase;
	}

	&--5 {
		font-family: $font-family-druk;
		font-size: 18px;
		font-weight: $font-weight-black;
		color: $color-gray-dark;
	}

	&--device-info {
		font-family: $font-family-default;
		font-size: 16px;

		@media (max-width: $grid-md-breakpoint) {
			font-size: 14px;
		}

		@media (max-width: $grid-sd-breakpoint) {
			font-size: 13px;
		}
	}

	.icon {
		fill: currentColor;
	}
}
