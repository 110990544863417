// Paths
// ======================================================
$path-fonts: "/assets/fonts";
$path-images: "/assets/images";
// $path-webpack-images: $path-images;

// Fonts
// ======================================================
$font-family-default: "SpaceMono";
$font-stack-default: $font-family-default, sans-serif;

$font-family-primary: "SpaceMono";
$font-stack-primary: $font-family-primary, sans-serif;

$font-family-druk: "DrukWide-Medium";
$font-stack-druk: $font-family-druk, sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-black: 900;

// Colors
// ======================================================
$color-gray-extralight: #f9f9f9;
$color-gray-lighter: #bcbcbc;
$color-gray-light: #868791;
$color-gray-dark: #43414e;

$color-blue-light: #00aeef; // or #00d2ff ?
$color-blue-basic: #20242f; // or #232530 ?
$color-blue-dark: #1b1f27;
$color-blue-extradark: #111319;

$color-red-basic: #e74c3c; // or #b60404 ?

$color-green-basic: #00ff1e;

$color-yellow-light: #fbf1c2;

// Z-indexes
// ======================================================
$z-indexes: (
	modal: 31,
	modal-container: 30,
	table-header: 21,
	table-header-sticky-background: 3,
	loader-spinner: 11,
	loader: 10
);

// Breakpoints
// ======================================================
$breakpoints: (
	xs: 599,
	xxl: 1400,
	modal: 680,
	page: 1060
);

// Grid View Breakpoints
// =============================================================================

$grid-xl-breakpoint: 1820px;
$grid-lg-breakpoint: 1640px;
$grid-md-breakpoint: 1440px;
$grid-sm-breakpoint: 1280px;
$grid-xs-breakpoint: 1185px;
$grid-xxs-breakpoint: 1060px;
$grid-sd-breakpoint: 960px;

// List View Breakpoints
// =============================================================================

$list-xl-breakpoint: 1720px;
$list-lg-breakpoint: 1450px;
$list-md-breakpoint: 1100px;
$list-sm-breakpoint: 850px;
$list-xs-breakpoint: 500px;

// Constants
// =============================================================================

$transition-duration-slower: .4s;
$transition-duration-basic: .2s;
$transition-duration-quick: .1s;
$transition-timing-function-default: ease-in-out;

$border-radius-basic: 3px;
$border-radius-large: 5px;
$border-radius-huge: 10px;

$line-height-broader: 1.3;
