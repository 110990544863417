@import "../helpers/variables";
@import "../helpers/mixins";

.table {
	$header-height: 68px;

	right: 145 / 1920 * 100vw;
	left: 75 / 1920 * 100vw;

	margin-top: 50px;

	&__row {
		transition: background $transition-duration-basic $transition-timing-function-default;
	}

	&__cell {
		padding: 42px 0 42px 16px;

		@media (max-width: $list-md-breakpoint) {
			padding: 22px 0 22px 16px;
		}

		@media (max-width: $list-xs-breakpoint) {
			padding-left: 0;
		}

		&--center {
			text-align: center;
		}

		&--phone {
			position: relative;
			height: 100px;
		}

		&--secondary {
			opacity: .2;
		}

		&--on-hover:not(:hover) {
			opacity: 0;
		}

		&--status {
			padding-left: 16px;

			@media (max-width: $list-xs-breakpoint) {
				padding-left: 0;
			}
		}
	}

	&__phone {
		position: relative;
		top: 18px;
	}

	/**
	 * Header shadow:
	 */

	&__header-shadow {
		display: none;
	}

	&--with-header-shadow {
		position: absolute;
	}

	&--with-header-shadow &__header-shadow {
		position: absolute;
		top: $header-height;
		right: -(145 / 1920 * 100vw);
		left: -(75 / 1920 * 100vw);
		z-index: -1;
		display: block;
		height: 60px;
		background: transparent linear-gradient(rgba(#000, .15), rgba(#000, 0));
	}

	&__header-loading {
		&-linear {
			position: absolute;
			right: -(145 / 1920 * 100vw);
			left: -(75 / 1920 * 100vw);
			width: auto;
		}

		&-circular {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 20;
			margin: auto;
		}
	}

	/**
	 * Sticky header:
	 */

	@supports (position: sticky) {
		// Making the full-width shadow "stick" to the top:
		&--sticky &__header-shadow {
			position: fixed;
		}

		// Creating a background for that "stuck" header:
		&--sticky &__header::after {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: z(table-header-sticky-background);
			display: block;
			height: $header-height;
			background: linear-gradient(45deg, #3b2634, #202631) fixed;
			content: "";
		}

		// Oh, Firefox, you and your `position: sticky` + `<table>` bug...
		// https://bugzilla.mozilla.org/show_bug.cgi?id=975644
		/*@include isFirefox {
			&--sticky &__header-shadow {
				position: absolute;
			}

			&--sticky &__header::after {
				display: none;
			}
		}*/
	}
}

// v-data-table header
// @TODO: make it sticky
.theme--dark.v-data-table thead tr:last-child th {
	@include isFirefox {
		height: 54px; // height -13px
	}

	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: z(table-header-sticky-background);
	height: 67px;
	background: linear-gradient(45deg, #3b2634, #202631) fixed;
	border-bottom: 0;
}

.v-data-table-header {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: z(table-header-sticky-background);
	height: 67px;
	background: linear-gradient(45deg, #3b2634, #202631) fixed;
}

// v-list-table table
.theme--dark.v-data-table {
	background: transparent;
}

// Rows
.v-data-table td {
	height: 60px;
	padding: 0;
	font-size: 16px;
	white-space: nowrap;
	vertical-align: middle;

	cursor: pointer;
}

// Rows
.v-data-table td:first-child {
	padding-left: 16px;
	@media (max-width: $list-xs-breakpoint) {
		padding-left: 0;
	}
}

.v-data-table th {
	padding-top: 16px;
	padding-left: 16px;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	vertical-align: baseline;

	@media (max-width: $list-xs-breakpoint) {
		padding-left: 0;
	}
}

.v-data-table__wrapper {
	margin-bottom: 82px;
	overflow: visible;
}

// Border on rows
.theme--dark.v-data-table tbody tr:not(:last-child) {
	border-bottom: 0;
}

.theme--dark.v-data-table tbody tr:not(.v-row-group__header) + tr:not(.v-row-group__header) {
	border-top: 1px solid hsla(0, 0%, 100%, .12);
}

.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
	background: 0;
}

// Hover transition on row
.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expand-row):not(.v-data-table__empty-wrapper):not(.v-row-group__header) {
	background: $color-blue-dark;
}

.theme--dark.v-data-table .v-row-group__header {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	width: 0;
	height: 90px;
	background: transparent;

	@media (max-width: 600px) {
		height: 60px;
	}
}

.table .v-simple-checkbox {
	padding-left: 2px;
	line-height: inherit;
}

.table .mdi-checkbox-blank-outline {
	font-size: 20px;
	color: #fff;
	opacity: .3;
}

.table tr .mdi-checkbox-marked,
.table tr .mdi-minus-box {
	font-size: 20px;
	color: #145cff;
}

.table tr .mdi-checkbox-marked::before,
.table tr .mdi-minus-box::before {
	background: linear-gradient(#fff, #fff) no-repeat, linear-gradient(#fff, #fff) no-repeat;
	background-position: 4px;
	background-size: 12px 12px;
}

.table tr th .mdi-checkbox-blank-outline:hover,
.table tr:hover td .mdi-checkbox-blank-outline {
	opacity: 1;
}
