.navigation {
	&__item {
		padding: 0 30 / 1920 * 100vw;
		text-transform: unset;
	}

	&__link {
		display: block;
		padding: 10px 20 / 1920 * 100vw;

		font-family: $font-stack-default;
		font-size: 15px;
		font-weight: $font-weight-semibold;

		color: #fff;
		text-decoration: none;

		&__dark {
			padding: 25px;
			color: inherit;
		}

		&__disabled {
			color: #808080;
		}
	}
}
